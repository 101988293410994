import './App.css';
import {Route, Routes} from "react-router-dom";
import Registration from "./pages/registration/Registration";
import Header from "./pages/header/Header";
import Main from "./pages/main/Main";
import Login from "./pages/login/Login";


function App() {
    return (
        <div className="App">
            <Header/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/registrations" element={<Registration/>}/>
            </Routes>
        </div>
    );
}

export default App;
