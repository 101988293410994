import "./Header.css";
import {Button, Heading, Pane} from 'evergreen-ui';
import {Link} from "react-router-dom";


function Header() {
    return (
        <Pane display="flex" padding={16} background="tint2" borderRadius={3}>
            <Pane flex={1} alignItems="center" display="flex">
                <Heading size={600}>HobaHoba Tool box</Heading>
            </Pane>
            <Pane>
                {/* Below you can see the marginRight property on a Button. */}
                <Link to="/login" class='Link'><Button marginRight={16}>Log in</Button></Link>
                <Link to="/registrations" class='Link'><Button appearance="primary" to="/registrations">Sign Up</Button></Link>
            </Pane>
        </Pane>
    );
}

export default Header;
